* {
  font-family: 'Source Sans Pro', sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}

:root {
  --black: #000;
  --green-teal: #0eba5e;
  --blueish: #1575a9;
  --sapphire: #2533b6;
  --white: #ffffff;
  --greyish-brown: #494949;
  --grey: #f4f4f4;
  --green1: #1b9aaa;
  --green2: #06d6a0;
  --orange: #F98724;
}

.app-container {
  margin-top: 5px;
}

a.regular-link,
.date {
  color: #315E90;
  font-size: 14px;
}

/* Login */

div.login-container {
  height: 81vh;
  background: url(../images/background_login.jpg) no-repeat center bottom fixed;
  background-size: 100% 60%;
}

input.login-input {
  margin-bottom: 10px;
}

.login-label {
  color: #626262;
}

/* Navbar */

.navbar-content {
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 100%;
  grid-column: 2;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-top: 5px;
}

.navbar-header {
  font-weight: 600;
  min-width: 170px;
}

.nav-logo-container {
  align-items: center;
  display: flex;
  padding: 0 0 0 15px;
}

.nav-logo {
  width: 100%;
}

.nav-link {
  cursor: pointer;
}

.nav-links-group {
  list-style-type: none;
  margin: 0;
}

.nav-link-element,
.nav-link,
.dropdown-item {
  color: #315E90 !important;
}

.nav-link-element {
  display: inline-block;
}

.nav-link-element.dropdown.nav-item {
  padding-top: 10px;
}

.nav-link-element.nav-link.username {
  padding: 15px 20px 0 0;
}

.rt-thead.-filters {
  padding-left: 10px;
}

.login-column.logo {
  text-align: center;
  clear: right
}

.dropdown-menu .dropdown-item {
  padding: .25rem 0;
}

.dropdown-menu {
  padding: 0 !important;
}

.navbar-header.dropdown-toggle::after {
  float: right;
  margin-top: .5625em;
}

button.nav-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

button.nav-link {
  margin: 8px 5px;
}

/* Breadcrumbs */
.breadcrumb {
  background: transparent !important;
  font-size: 22px;
}

.breadcrumb-item {
  font-weight: bold;
}

.breadcrumb-item.active,
.breadcrumb-item+.breadcrumb-item::before {
  color: #315E90 !important;
}

.principal.breadcrumb-item,
.principal.breadcrumb-item a {
  color: var(--orange) !important;
}

/* Tables */

.table-cell,
.table-header {
  color: #315E90;
}

button.table-link,
a.table-link {
  color: #F98724 !important;
  cursor: pointer;
}

button.table-link.disabled,
a.table-link.disabled {
  color: grey !important;
}

.table-header {
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
}

.table-cell {
  font-size: 14px;
  padding: 5px 5px 5px 10px;
}

.table-cell.wrap {
  white-space: normal;
  word-break: break-word;
}

.rt-thead.-filters {
  background: #EFEFEF;
}

.ReactTable .rt-thead.-filters .rt-th {
  border: none;
}

.ReactTable .rt-noData {
  z-index: 0 !important;
}

button.table-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #F98724 !important;
  cursor: pointer;
}

button.table-link:focus {
  outline: none;
}

/* React Select */

.Select.is-open {
  z-index: 999;
}

/* Forms */

.form-box {
  background: whitesmoke;
  padding: 20px 15px;
  border-radius: 5px;
  border: solid #d8d8d8 1px;
  margin: 15px 0;
}

.form-title {
  font-size: 18px;
  margin-bottom: 15px;
}

input.form-control {
  font-size: 14px;
}

input.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.col-form-label {
  font-weight: bold;
  font-size: 15px;
}

input.disabled:focus {
  outline: none;
}

.form-label {
  font-weight: bold;
}

span.required.text-danger {
  margin-left: 1px;
}

button.form-button.btn.btn-secondary.disabled {
  cursor: not-allowed;
}

.modal-body-grupo {
  padding: 10px 50px !important;
}

#region,
#comuna,
#actividad,
#organization_type,
#roles,
#alias-region,
#alias-comuna,
#perfiles,
#grupo {
  font-size: 14px;
}

.fa-save {
  margin-right: 5px;
}

button.alias-button,
button.alias-button:hover {
  background: #315E90;
  font-size: 14px;
  font-weight: normal;
  width: 125px;
}

button.alias-button:focus {
  box-shadow: 0 0 0 0;

}

div.alias-form input[type=text] {
  height: 100%;
}

div.alias-form input.form-control:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.ReactTable.-highlight.alias-table {
  border-bottom: none;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  padding: 8px 7px;
}

.alias-table div div.rt-thead.-header {
  padding: 0 5px !important;
  font-size: 14px;
}

.alias-table div div.rt-tbody {
  background: #FFFFFF;
}

.alias-table .table-header {
  font-weight: normal;
  padding-left: 8px;
}

.alias-table div {
  overflow-x: unset !important;
}

.alias-table .table-link {
  color: #CCCCCC !important;
}

.alias-table .table-cell {
  padding: 0 0 0 12px;
}

/* Spinner */
.sk-circle {
  margin: 15px auto;
  width: 55px;
  height: 45px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #F98724;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ReactTable .rt-th {
  overflow: visible;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 30px 45px;
  width: 100%;
  background-color: #292B33;
  letter-spacing: -0.22px;
  line-height: 18px;
  color: #CCCCCC;
  font-size: 14px;
  text-align: center;
}

.env-message {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: .75rem 1.25rem;
  /* default staging values */
  border: 2px dashed #F59A11;
  background-color: #F4B454;
  color: white;
}