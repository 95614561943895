@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 700px !important;
    }
}

.modal-content {
    border-radius: 0 !important;
}

.modal-content,
.modal-footer,
.modal-header {
    border: none !important;
}

.token.modal-body {
    color: #315E90;
}

.token.modal-body {
    padding-top: 5px;
}

.modal-dialog.modal-warning {
    max-width: 100% !important;
    bottom: 0;
    position: fixed;
    width: 100% !important;
    margin-bottom: 0;
}

button.modal-button.btn.btn-secondary:active,
button.modal-button.btn.btn-secondary,
button.modal-button.btn.btn-secondary:focus {
    background-color: transparent !important;
    width: 100% !important;
    color: #F98724 !important;
    box-shadow: 0 0 0 0;
    border: none !important;
    text-align: left !important;
}

.btn-secondary.focus, .btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
button.close.delete:hover{
    box-shadow: 0 0 0 0 !important;
}

button.close.delete {
    margin-top: -12px;
}

button.close.delete:focus {
     outline: none !important;
}

.warning-button {
    color: #D32D2D;
    margin-top: 2px;
}

.modal-dialog.modal-warning.warning-container.modal-lg {
    padding: 0 !important;
}

.warning-container,
.warning-container div.button-container {
    display: flex;
}

div.button-container {
    justify-content: space-around;
}

.warning-container {
    padding: 30px 20px!important;
    justify-content: center;
}

.warning-container div.warning-section {
    margin: 0 40px;
}

.warning-container div.warning-section.text {
    display: flex;
    padding: 4px 0 0 0;
    color: #626262;
    font-weight: bold;
}

.warning-container div.warning-section.text div {
    margin: 0 2px;
    vertical-align: middle;
}

.warning-container div.warning-section.text .icon-container {
    color: #F98724;
    font-size: 22px;
    margin: -4px 10px 0 0;
}

.centered-horizontal-container {
    display: flex;
    justify-content: center;
}

.warning-container div.button-container div {
    margin: 0 8px;
}

button.warning-cancel,
button.warning-accept {
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 165px;
    border-radius: 6px;
}

button.no-bold {
    font-weight: 400;
}

button.token-accept {
    font-size: 14px;
    color: white;
    width: 125px;
    border-radius: 6px;
    padding: 4px 0;
}

button.warning-cancel,
button.warning-cancel:hover,
button.warning-cancel:active {
    background: #315E90 !important;
    border-color: #315E90 !important;
}

button.warning-accept,
button.token-accept,
button.token-accept:hover,
button.token-accept:active {
    background: #F98724 !important;
    border-color: #F98724 !important;
}

.token .modal-header {
    padding: 0;
}

.modal-header button.close {
    font-size: 45px;
    font-weight: 300;
    color: #E67B2E;
    opacity: inherit;
    margin-top: -2%;
    padding: 5px 25px 0 1rem;
}

.modal-header button.close:focus {
   outline: 0 !important;
}

.token.modal-body .title-text {
    font-size: 20px;
}

.token.modal-body .body-text {
    font-size: 14px;
    line-height: 18px;
    padding: 25px 0 10px 20px;
}

.token.modal-body .body {
    padding: 0 30px;
}

.token.modal-body .modal-footer {
    padding-right: 8%;
}

h5.modal-title {
    font-weight: bold;
    margin-top: 10px;
    padding-left: 25px;
}

/*Forms*/
.modal-body .label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
}

.modal-body .value {
    font-size: 14px;
}

.modal-body .form-button {
    background: #F98724;
    border-color: #F98724;
}

.modal-body .form-button,
.modal-body .form-button:active {
    height: auto;
    font-size: 14px;
    background: #F98724 !important;
    border-color: #F98724 !important;
    padding: 4px 1rem;
}

.modal-body .form-button.cancelar {
    background: none !important;
    border: none;
    color: #F98724 !important;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
}

.modal-body .form-button.guardar,
.modal-body .form-button.guardar:active {
    color: #F98724 !important;
    background: white !important;
    /*margin-right: 0.5rem;*/
}

.modal-body .button-container {
    display: flex;
}

.modal-body .button-container button {
    margin-left: 1.5rem;
}


.modal-body .form-button.cerrar,
.modal-body .form-button.cerrar:active {
    color: #F98724 !important;
    background: white !important;
}

.form-block input {
    font-size: 14px;
}

/* Crear línea */
.create-line h5.modal-title {
    padding-left: 10px;
    margin-top: 12px;
}

.create-line div.body {
    padding: 0.5rem 1.8rem 2.5rem;
}

.create-line div.body-text {
    padding: 0 1.8rem;
}

#client, #afianzado {
    font-size: 14px;
    color: #626262;
}

input[name="client"] {
    color: #626262;
}

.create-line div.body .label.secondary {
    margin-top: 1.5%;
    font-size: 14px;
    font-weight: normal;
}

.create-line .text-right.modal-footer {
    padding: 1rem 3.5rem;
}

.create-line .text-right.modal-footer button {
    width: 125px;
}

/* Uso Detail */
div.uso-body {
    padding: 15px 45px 0 45px;
}

div.uso-body div.row {
    margin-bottom: 50px;
}

div.uso-body div.label {
    margin-bottom: 4px;
}

div.uso-button-container {
    padding: 0 50px 15px 50px;
    margin-top: -10px;
}

a.poliza.table-link {
    font-weight: normal;
    margin-left: 10px;
    text-decoration: underline;
    margin-bottom: 10px;
}

a.poliza span.text {
    font-size: 12px;
    border-bottom: none !important;
}

a.poliza span.icon {
    margin-left: 5px;
}

/* Edit Line */
div.edit-line-body .row{
    margin-bottom: 5px;
}

div.edit-line-body {
    padding: 0 6% 5px 6%;
}

div.edit-line-body .row {
    margin-bottom: 20px;
}

.file-upload {
    margin-top: 15px;
}

.file-upload input:focus,
.file-upload:focus{
    outline: none !important;
}

.file-upload input[type="file"] {
    display: none;
}

.file-upload label {
    border: 1px solid #CCCCCC;
    background-color: #EFEFEF;
    color: #626262;
    display: table;
    font-size: 12px;
    padding: 0 15px;
    border-radius: 4px;
    cursor: pointer;
}

.form-feedback {
    font-size: 14px;
    margin: 15px 0 0 10px;
}

div.label.nuevo-monto {
    margin-top: 15%;
}

.radio-container {
    margin-top: 35px;
    font-size: 14px;
}

.adenda-warning {
    font-size: 14px;
}

.line-mov-error-msg:first-letter {
    text-transform: uppercase;
}

/* LineForm */
.first-warning {
    font-size: 14px;
    font-weight: bold;
}

/* Toggle Input */
.switch {
    padding-left: 3%;
}

/* Detalle Línea */
div.detalle-line-body {
    padding: 0 25px 20px 25px;
}

div.detalle-line-body div.row.form {
    margin-bottom: 30px;
}

div.detalle-line-body div.button-container {
    padding-top: 15px;
}

/*fileList Table*/
div.archivo-table {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 0 !important;
    overflow: hidden;
}

.file-list-row {
    font-size: 14px;
    margin: 0 !important;
}

.file-list-row.dark {
    background-color: #EFEFEF !important;
}

.file-list-row div {
    padding: 7px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-list-row div button.link-button {
    font-size: 16px;
    margin-top: -7px;
}

.file-name {
    margin-top: 4px;
    margin-left: 2%;
    font-size: 14px;
    color: #626262;
    font-style: italic;
}

.error-msg {
    font-size: 14px;
    color: #D32D2D;
}